<template>
  <div>
    <template v-if="showButton">

      <button v-show="!this.hideButton"
        class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-primary" @click="openDialog">
        <i class="btn-icon-wrapper" style="display:block !important;">
          <font-awesome-icon :icon="['fal', 'random']" />
        </i><span v-if="showButtonText">{{ $t('generic.lang_cashier_mergeTable') }}</span>
      </button>

      <v-dialog v-model="dialogOpen" max-width="600px" scrollable persistent :fullscreen="$vuetify.breakpoint.smAndDown">
        <v-card min-height="400">
          <v-toolbar v-if="$vuetify.breakpoint.smAndDown" color="#FF6969" dense flat max-height="60">
            <v-toolbar-title class="text-white">{{ $t('generic.lang_moveParty') }}</v-toolbar-title>

            <v-spacer />

            <v-btn class="text-white" icon @click="dialogOpen = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-toolbar v-else dense flat max-height="60">
            <v-toolbar-title>{{ $t('generic.lang_moveParty') }}</v-toolbar-title>

            <v-spacer />

            <v-btn icon @click="dialogOpen = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider class="ma-0" />
          <v-card-text v-if="loading" class="text-center">
            <v-progress-circular color="primary" indeterminate size="40"
              style="margin-top: 20px;"></v-progress-circular>
          </v-card-text>
          <v-card-text v-else>
            <v-container fluid>
                <v-row dense>
                <v-col cols="12" sm="6" class="h-100">
                  <v-card-title class="pb-2">{{ $t('generic.lang_selectParty') }}</v-card-title>
                  <v-list class="overflow-y-auto py-0" elevation="1" outlined dense max-height="350">
                    <v-list-item-group v-model="selectedParty" active-class="primary-lighten-4" mandatory>
                      <v-list-item v-for="(party, index) in tableParties" :key="party.name" class="border-bottom" outlined :value="party.name">
                        <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="wrap-text">{{ party.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="6" class="h-100">
                  <v-text-field v-model="searchTable" outlined dense hide-details class="ma-2"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                    :label="$t('tablebee.lang_search_table')" />
                  
                  <v-list v-if="filteredTables.length > 0" class="overflow-y-auto pa-1" elevation="1" outlined dense max-height="350">
                    <v-list-item-group v-model="selectedTable" active-class="primary-lighten-4">
                      <v-list-item v-for="(table, index) in filteredTables" :key="table.name" class="border-bottom" :value="table.name">
                        <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="wrap-text">{{ table.name }}</v-list-item-title>
                          <template v-if="table.parties?.length">
                            <v-list-item-subtitle v-for="(party, key) in table.parties" :key="`${table.name}-${party.name}-${key}`">{{ party.name }}</v-list-item-subtitle>
                          </template>
                        </v-list-item-content>
                      </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <h5 v-else class="text-center my-5" > {{ $t("generic.lang_notFound") }}</h5>
                </v-col>
              </v-row>
              
            </v-container>
          </v-card-text>
          <v-card-actions :class="this.$vuetify.breakpoint.smAndDown ? 'card-footer pt-1 pb-1 pl-0 pr-0' : ''">
            <v-btn color="error" text @click="dialogOpen = false">{{ $t('generic.lang_cancel') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn :class="this.$vuetify.breakpoint.smAndDown ? 'text-white' : 'text-white'"
              :color="this.$vuetify.breakpoint.smAndDown ? '#FF6969' : 'success'"
              :elevation="this.$vuetify.breakpoint.smAndDown ? '0' : '3'" :rounded="this.$vuetify.breakpoint.smAndDown"
              :disabled="!selectedParty || !selectedTable || loading2 || loading"
              :loading="loading2 || loading"
              @click="moveParty"
              :small="this.$vuetify.breakpoint.smAndDown">
              {{ $t('generic.lang_move') }}
            </v-btn>
          </v-card-actions>
          <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
            <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
              :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
              :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidthExpanded" />
          </div>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<style scoped>
.rotate-45 {
  rotate: 50deg;
}

.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'

import { faRandom } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ENDPOINTS } from "@/config";
import { mapGetters, mapState } from "vuex";
import { Events } from "@/plugins/events";
import KeyboardMixIns from "@/mixins/KeyboardMixIns";

library.add(
  faRandom
);

export default {
  name: "ButtonsMoveParty",

  components: {
    'font-awesome-icon': FontAwesomeIcon
  },
  mixins:[ KeyboardMixIns ],
  props: {
    hideButton: {
      type: Boolean,
      default: false
    },
    posType: String,
    showButtonText: Boolean
  },

  data() {
    return {
      dialogOpen: false,
      warningDialog: false,
      warning: null,
      searchTable: null,
      selectedParty: null,
      selectedTable: null,
      loading: false,
      loading2: false,
      loadingPartyMerge: false,
      tables: [],
      parties: [],
    }
  },
  computed: {
    ...mapGetters({
      lockedTables: 'pos/gastro/lockedTables',
      tableParties: 'pos/gastro/tableParties',
      openItems: 'pos/gastro/openItems',
      orderedItems: 'pos/gastro/orderedItems',
      tableName: 'pos/gastro/tableName'
    }),
    filteredTables() {
      if (!(this.tables && this.tables.length > 0)) {
        return [];
      }
      return _.orderBy(this.tables.filter(table => {
        return table.name !== this.tableName && (!this.searchTable || table.name?.includes(this.searchTable))
      }), 'name')
    },
    ...mapState([
      'pos',
    ]),
    ...mapGetters({
      currentParty: "pos/gastro/currentParty"
    }),
    showButton() {
      return this.tableName !=0;
    }
  },

  watch: {
    dialogOpen(val) {
      if (val) {
        this.collectData();
      } else {
        this.resetData();
      }
    },
  },

  methods: {
    openDialog() {

      //check if we have ordered items
      if (this.orderedItems?.length === 0 || this.openItems?.length > 0) {
        Events.$emit("showSnackbar", {
          message: this.$t('erp.lang_errorOrderFirst'),
          color: "warning"
        });
      } else {
        //CHECK IF WE ARE ON TRESENVERKAUF
        if (this.tableName == 0)
          return;

        this.dialogOpen = true;
      }

    },
    resetData() {
      this.searchTable = null;
      this.selectedParty = null;
      this.selectedTable = null;
    },
    collectData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GET).then((res) => {
        if (res.data.success) {
          this.tables = res.data.tables;

          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    moveParty() {
      this.loading2 = true;

      this.axios.post(ENDPOINTS.POS.GASTRO.MOVEPARTY, {
        sourceTable: this.tableName,
        targetTable: this.selectedTable,
        partyName: this.selectedParty,
      }).then((res) => {
        this.loading2 = false;
        this.dialogOpen = false;
        if (res.data.success) {
          if (this.$vuetify.breakpoint.smAndDown) {
            this.$router.replace({ name: 'pos.gastro.tresenverkauf' })
          } else {
            this.$router.replace({ name: 'pos.gastro.tables' })
          }
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')+' : ' + res.data.msg,
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')+' : ' + res.data.msg,
            color: "error"
          });
      }).finally(()=>{
        this.loading2 = false;
      })
    },
  }
}
</script>