<template>
  <v-container
    class="pa-0 ma-0 mx-0 transparent"
    fluid
  >
    <v-row
      class="mx-0 ma-0 px-0 pa-0 pt-3"
      no-gutters
    >
      <v-btn
        id="back-btn"
        fab
        @click="closeDialog()"
      >
        <font-awesome-icon
          :icon="['fal', 'chevron-left']"
          class="itemgroupIcon mx-auto"
          style="color: #ff6969; font-size: 30px"
        />
      </v-btn>
      <v-col
        class="pl-2"
        cols="8"
      >
        <v-switch
          v-model="bewirtungsbeleg"
          :color="this.colors.primary"
          class="ma-0 mx-0 pb-0"
          :label="$t('generic.lang_Bewirtungsbeleg')"
        />
        <v-switch
          v-model="offSite"
          :false-value="0"
          :true-value="1"
          :color="this.colors.primary"
          class="ma-0 mx-0 pt-0"
          :label="$t('erp.lang_ausserHausTax')"
          style="margin-top: -8px !important"
        />
        <v-switch
          v-model="restAsTip"
          :label="$t('generic.lang_restAsTip')"
          class="ma-0 pa-0"
          hide-details
          color="#ff6969"
        ></v-switch>
      </v-col>

      <v-col
        class="text-right pr-2 pt-3"
        cols="4"
      >
      </v-col>

      <v-col
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : 'bg-white']"
        class="pr-2 pl-2 pt-0"
        cols="12"
        style="width: 110% !important"
      >
        <v-row
          align="center"
          no-gutters
        >
          <v-col
            class="pb-0"
            cols="6"
          >
            <v-list-item
              v-if="!!this.customer"
              class="pl-0"
            >
              <v-list-item-avatar
                color="grey"
                size="25"
              >
                <v-icon> person </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bolder pt-1 font-size-xlg"
                >
                  <span v-bind:style="{ color: this.colors.primary }">
                    {{
                      (this.customer.first_name != null
                        ? this.customer.first_name
                        : '') +
                      ' ' +
                      (this.customer.last_name != null
                        ? this.customer.last_name
                        : '')
                    }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col
            class="text-right pr-0 pt-2"
            cols="6"
          >
            <!--            <v-btn class="bg-white pr-1 pl-1" rounded small>
                                      <span class="text-muted">
                                        TRINKGELD
                                      </span>
                          <span class="rounded-circle ml-1 pa-1" v-bind:style="{'background-color': this.colors.primary}">
                                                      <font-awesome-icon
                                                          :icon="['fal', 'heart']"
                                                          v-bind:style="{color: 'white'}"
                                                      />
                                      </span>
                        </v-btn>-->
          </v-col>

          <v-col
            class="pt-0"
            cols="4"
          >
            <v-list-item-content class="pt-0">
              <v-list-item-subtitle class="text-muted">
                {{ $t('generic.lang_receipt_gegeben') }}
              </v-list-item-subtitle>
              <v-list-item-title class="pt-1 font-size-xlg">
                <span class="text-color text-font">
                  {{ this.moneyGiven | currency }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-col>

          <v-col
            class="pt-0"
            cols="3"
          >
            <v-list-item-content class="pt-0">
              <v-list-item-subtitle class="text-muted text-uppercase">
                {{ $t('erp.lang_rest') }}
              </v-list-item-subtitle>
              <v-list-item-title class="pt-1 font-size-xlg">
                <span class="text-color text-font">
                  {{ this.moneyBack | currency }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-col>

          <v-col
            class="text-right"
            cols="5"
          >
            <!--            <v-btn class="bg-white pr-1 pl-1" rounded small>
                          <span class="text-muted">
                            TRINKGELD
                          </span>
                          <span class="rounded-circle ml-1 pa-1" v-bind:style="{'background-color': this.colors.primary}">
                                          <font-awesome-icon
                                              :icon="['fal', 'heart']"
                                              v-bind:style="{color: 'white'}"
                                          />
                          </span>
                        </v-btn>-->

            <v-list-item-content class="pt-0">
              <v-list-item-subtitle class="text-right tetx-muted">
                {{ $t('generic.lang_total') }}
              </v-list-item-subtitle>
              <v-list-item-title class="pt-1 font-size-xlg text-right">
                <span
                  class="text-font"
                  v-bind:style="{ color: this.colors.primary }"
                >
                  {{ this.total | currency }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        class="mt-0"
        cols="12"
      >
        <cashout-keyboard
          @change="typed($event)"
          @del="del"
        />
      </v-col>

      <v-col
        :class="[this.$vuetify.theme.dark ? '' : 'white']"
        class="mt-0"
        cols="12"
      >
        <div
          class="grid-menu grid-menu-3col rounded"
          style="border-radius: 30px !important"
        >
          <v-row
            align="center"
            class="pa-0"
            no-gutters
            style="border-radius: 30px !important"
          >
            <!-- start select customer -->
            <v-col
              class="text-center"
              cols="4"
            >
              <b-button
                :class="[this.$vuetify.theme.dark ? 'dark-bg' : 'bg-white']"
                class="ma-auto btn-icon-vertical text-muted btn-square btn-transition"
                style="height: 110px !important"
                @click="redirect('customer')"
              >
                <font-awesome-icon
                  :icon="['fal', 'users']"
                  class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                  v-bind:style="{ color: this.colors.primary }"
                />
                <span class="icons_text">
                  {{ this.$t('customers.lang_customers') }}
                </span>
              </b-button>
            </v-col>
            <!-- start select customer -->

            <!-- start: dynamic payments -->
            <v-col
              v-for="(payment, key) in filteredPayments"
              :key="key"
              cols="4"
            >
              <b-button
                :class="[
                  $vuetify.theme.dark ? 'dark-bg' : 'bg-white',
                  payment.paymentID === 1
                    ? 'btn-success'
                    : 'btn-outline-primary',
                ]"
                class="ma-auto btn-icon-vertical text-muted btn-square btn-transition"
                style="height: 110px !important"
                :disabled="disableCardPayment(payment.paymentID)"
                @click="
                  payment.paymentID === 7
                    ? toggleSplitDialog()
                    : selectPayment(payment.paymentID)
                "
              >
                <template v-if="!disableCardPayment(payment.paymentID)">
                  <i
                      v-if="payment.paymentID === 3 && (payments.ecPaymentType === 1 || payments.ecPaymentType === 2)"
                      class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                      v-bind:style="{ color: colors.primary }"
                  >
                    <img
                        :src="payments.ecPaymentType === 1 ? zvt : adyen"
                        width="70%"
                    />
                  </i>

                  <font-awesome-icon
                      v-else
                      :icon="[
                    'fal',
                    paymentIcons.hasOwnProperty(payment.paymentID)
                      ? paymentIcons[payment.paymentID]
                      : paymentIcons[0],
                  ]"
                      class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                      v-bind:style="{ color: colors.primary }"
                  />

                  <span
                      v-if="payment.paymentID === 3 && (payments.ecPaymentType === 1 || payments.ecPaymentType === 2)"
                      class="icons_text"
                  >
                  {{' '}}
                </span>
                  <span
                      v-else
                      class="icons_text"
                  >
                  {{ payment.paymentName }}
                </span>
                </template>

              </b-button>
            </v-col>

            <!-- end: dynamic payments -->

            <!-- ---------------------- HOTEL PMS BUTTONS ------------------- -->
            <v-col
              cols="4"
              v-if="$store.getters['permissions/checkModule'](43)"
            >
              <ButtonsIbelsaSearch
                @roomSelected="ibelsaRoomSelected"
              ></ButtonsIbelsaSearch>
            </v-col>

            <v-col
              cols="4"
              v-if="$store.getters['permissions/checkModule'](44)"
            >
              <ButtonsHS3Search
                @occupationSelected="hs3OccupationSelected"
                @occupationGuestSelected="hs3OccupationGuestSelected"
              ></ButtonsHS3Search>
            </v-col>

            <v-col
              cols="4"
              v-if="$store.getters['permissions/checkModule'](45)"
            >
              <ButtonsSuite8Search
                @roomSelected="suite8RoomSelected"
              ></ButtonsSuite8Search>
            </v-col>

            <v-col
              cols="4"
              v-if="$store.getters['permissions/checkModule'](46)"
            >
              <ButtonsSIHOTSearch
                @payAsGuest="sihotPayAsGuest"
                @roomSelected="sihotRoomSelected"
              ></ButtonsSIHOTSearch>
            </v-col>
            <!-- --------------------------------------------------------------- -->

            <!-- ---------------------- VOFFICE BUTTON ------------------- -->
            <v-col
              cols="4"
              v-if="
                $store.getters['permissions/checkModule'](51) &&
                this.customer !== null
              "
            >
              <button
                @click="selectPayment(104)"
                class="btn-icon-vertical btn-square btn-transition payment-btn btn h-100"
              >
                <i class="btn-icon-wrapper">
                  <img
                    src="@/assets/images/voffice.png"
                    width="60%"
                    alt="Voffice"
                  />
                </i>
              </button>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <!-- Dialog: SPLIT PAYMENT-->
    <v-dialog
      v-model="splitDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card tile>
        <v-toolbar
          max-height="50"
          elevation="1"
          dense
          flat
          :color="$vuetify.theme.dark ? '' : '#f1f4f6'"
          class="ma-0"
          v-touch="{
            down: () => toggleSplitDialog(),
          }"
        >
          <v-row no-gutters>
            <v-col
              class=""
              cols="4"
            >
              <v-list-item-content class="">
                <v-list-item-subtitle class="text-muted">
                  {{ $t('generic.lang_receipt_gegeben') }}
                </v-list-item-subtitle>
                <v-list-item-title class="pt-1 font-size-xlg">
                  <span class="text-color text-font">
                    {{ givenSplit | currency }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-col>

            <v-col
              class=""
              cols="3"
            >
              <v-list-item-content class="">
                <v-list-item-subtitle class="text-muted text-uppercase">
                  {{ $t('erp.lang_rest') }}
                </v-list-item-subtitle>
                <v-list-item-title class="pt-1 font-size-xlg">
                  <span class="text-color text-font">
                    {{ remaining | currency }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-col>

            <v-col
              class="text-right"
              cols="5"
            >
              <v-list-item-content class="">
                <v-list-item-subtitle class="text-right text-muted">
                  {{ $t('generic.lang_total') }}
                </v-list-item-subtitle>
                <v-list-item-title class="pt-1 font-size-xlg text-right">
                  <span
                    class="text-font"
                    v-bind:style="{ color: this.colors.primary }"
                  >
                    {{ total | currency }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text class="pa-4">
          <v-row
            no-gutters
            v-for="(payment, key) in splitPayments"
            :key="key"
          >
            <v-col
              cols="12"
              sm="12"
              class=""
            >
              <v-text-field
                min="0"
                :suffix="$store.getters['settings/currencyData']('symbol')"
                dense
                v-model="payment.amount"
                height="51px"
                outlined
                class="pr-0"
                :label="payment.label"
                :rules="[rules.canSplit]"
                :ref="'payment' + payment.id"
                color="rgb(255, 105, 105)"
              >
                <template v-slot:prepend-inner>
                  <v-row
                    no-gutters
                    style="width: 50px"
                  >
                    <v-col align="start">
                      <i class="btn-icon-wrapper my-auto">
                        <font-awesome-icon
                          style="color: rgb(255, 105, 105)"
                          size="2x"
                          :icon="[
                            'fal',
                            paymentIcons.hasOwnProperty(payment.id)
                              ? paymentIcons[payment.id]
                              : paymentIcons[0],
                          ]"
                        />
                      </i>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:append>
                  <v-btn
                    depressed
                    elevation="0"
                    large
                    @click="rest(payment)"
                    class="ma-0 text-light"
                    light
                    style="
                      border-color: rgb(255, 105, 105) !important;
                      margin-top: -4.5px !important;
                      margin-right: -7px !important;
                    "
                    color="rgb(255, 105, 105)"
                  >
                    {{ $t('erp.lang_rest') }}
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="12"
              sm="12"
              align="end"
            >
              <v-btn
                :class="['ma-0', 'pa-0']"
                class="mx-0 ma-0"
                depressed
                block
                large
                style="
                  border-color: rgb(255, 105, 105) !important;
                  font-family: Anton;
                  color: white;
                "
                color="rgb(255, 105, 105)"
                :loading="loading"
                tile
                elevation="0"
                :disabled="!canPayWithSplit"
                @click="selectPayment(7)"
                >{{ $t('generic.lang_pay') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- -------------------------------------------------------- -->
    <!-- ------------- REQUIRED IMPORTS FOR CASHOUT ------------- -->
    <!-- -------------------------------------------------------- -->
    <v-dialog
      v-model="loading"
      persistent
      width="500"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center"
              >
                <v-progress-circular
                  indeterminate
                  :color="
                    this.$vuetify.breakpoint.smAndDown ? '#FF6969' : 'primary'
                  "
                  width="3"
                  size="50"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="errorDialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center"
              >
                <p style="font-size: 17px">{{ errorDialogMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            v-if="errorDialogShowCancelButton"
            :disabled="errorDialogLoading"
            text
            @click="cancelErrorDialog"
            >{{ $t('generic.lang_cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            :disabled="errorDialogLoading"
            :loading="errorDialogLoading"
            @click="closeErrorDialog"
          >
            {{ errorDialogConfirmButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CashoutCMIDialog
      ref="cmiDialog"
      :amount="total"
      @finished="cmiFinished"
      @cancel="loading = false"
    ></CashoutCMIDialog>
    <CashoutAdyenDialog
      ref="adyenDialog"
      :amount="total"
      @finished="adyenFinished"
      @cancel="loading = false"
    ></CashoutAdyenDialog>
    <CashoutZVTDiaog
      ref="zvtDialog"
      :amount="total"
      @finished="zvtFinished"
      @cancel="loading = false"
    ></CashoutZVTDiaog>
    <PrintOrderbon ref="printOrderBon"></PrintOrderbon>
    <the-tip-dialog ref="tipDialog"></the-tip-dialog>
    <!-- -------------------------------------------------------- -->
  </v-container>
</template>

<script>
/*
IMPORTANT: THIS MIXIN CONTAINS THE MAIN LOGIC!!! (only javascript)
*/
import cashoutMixin from '@/mixins/pos/cashout/cashoutMixin';
import { Events } from '../../../../plugins/events';

import { mapState } from 'vuex';

import CashoutKeyboard from '@/components/pos/mobile/wedgets/CashoutKeyboard';
import CashoutAdyenDialog from '@/components/pos/CashoutAdyenDialog';
import CashoutZVTDiaog from '@/components/pos/CashoutZVTDiaog';
import CashoutCMIDialog from '@/components/pos/CashoutCMIDialog.vue';
import PrintOrderbon from '@/mixins/pos/PrintOrderbon';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import ButtonsHS3Search from '@/components/pos/buttons/ButtonsHS3Search';
import ButtonsIbelsaSearch from '@/components/pos/buttons/ButtonsIbelsaSearch';
import ButtonsSuite8Search from '@/components/pos/buttons/ButtonsSuite8Search';
import ButtonsSIHOTSearch from '@/components/pos/buttons/ButtonsSIHOTSearch';
import TheTipDialog from '@/components/pos/TheTipDialog.vue';

export default {
  name: 'MobileCashout',

  components: {
    PrintOrderbon,
    CashoutCMIDialog,
    CashoutZVTDiaog,
    CashoutAdyenDialog,
    CashoutKeyboard,
    'font-awesome-icon': FontAwesomeIcon,
    ButtonsHS3Search,
    ButtonsIbelsaSearch,
    ButtonsSuite8Search,
    ButtonsSIHOTSearch,
    TheTipDialog,
  },

  props: {
    colors: {
      type: Object,
      required: true,
    },
    posType: {
      type: String,
      required: true,
    },
    invoiceItemsOverwrite: {
      type: Array,
      default: () => {
        return [];
      },
    },
    newOrderArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    splitRedirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      restAsTip: false,
      numpadClicks: 0,
    };
  },
  mixins: [cashoutMixin],

  computed: {
    ...mapState(['touchkeyboard', 'api', 'cashierIDs']),
    cashierOptions() {
      let opt = {};
      opt = this.cashierIDs.cashiers.find(
        (cashier) => cashier.id === this.api.auth.cashierID
      );
      return opt;
    },
  },

  mounted() {
    if (this.pos.gastro.takeAway === 0) {
      this.in_house = true;
    } else {
      this.away = true;
    }
    this.moneyGiven = this.total;
    //CHECK TAKEAWAY STANDARD CASE

    if (
      Number(this.cashierOptions.takeAwayStandard) === 1 &&
      this.pos.gastro.table.name === 0
    ) {
      if (Number(this.cashierOptions.inHouseTakeAwayRequired) === 1) {
        this.offSite = Number(
          Number(this.cashierOptions.takeAwayStandard) === 1 &&
            Number(this.pos.gastro.takeAway) === 1
        );
      } else {
        this.offSite = Number(this.cashierOptions.takeAwayStandard);
      }
    } else {
      this.offSite = Number(this.pos.gastro.takeAway);
    }
  },

  methods: {
    typed(val) {
      if (this.numpadClicks < 1) {
        this.moneyGiven = 0;
      }
      this.numpadClicks++;
      let tmp = this.moneyGiven.toString().split('.');
      if (Number(tmp[0]) === 0 && tmp[1] === undefined) {
        this.moneyGiven = Number(val) / 100;
      } else if (val === '00') {
        this.moneyGiven = Number(this.moneyGiven) * 100;
      } else {
        this.moneyGiven = Number(this.moneyGiven) * 10;
        this.moneyGiven += Number(val) / 100;
      }
      this.invoiceResultData.moneyGiven = this.moneyGiven;
    },
    redirect(route) {
      Events.$emit('redirect', 'customer');
      //this.$emit('redirect', 'customer');
    },
    del() {
      this.moneyGiven = 0;
      this.invoiceResultData.moneyGiven = 0;
    },
    clearCashier() {
      if (this.posType === 'gastro') {
        this.pos.gastro.takeAway = 0;

        if (this.pos.gastro.table.name === 0) {
          //CLEAR BOOKED ITEMS
          this.$store.dispatch('pos/gastro/setOpenItems', {
            party: this.pos.gastro.party,
            orders: [],
          });

          //this.$emit('redirect', 'items')
          this.$emit('redirect', 'send_invoice');
          this.$emit('setPaymentResponse', {
            invoiceResultData: this.invoiceResultData,
            invoiceXMLPrintData: this.invoiceXMLPrintData,
          });
        } else {
          //CLEAR BOOKED ITEMS
          this.$store.dispatch('pos/gastro/setOrderedItems', {
            partyName: this.pos.gastro.party.name,
            orders: [],
          });

          this.$emit('redirect', 'send_invoice');
          this.$emit('setPaymentResponse', {
            invoiceResultData: this.invoiceResultData,
            invoiceXMLPrintData: this.invoiceXMLPrintData,
          });
        }
      }

      if (this.posType === 'retail') {
        //CLEAR BOOKED ITEMS
        this.$store.commit('pos/retail/reset');
      }

      if (this.posType === 'wholesaleRetail') {
        //CLEAR BOOKED ITEMS
        this.$store.commit('pos/wholesaleRetail/reset');
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.numpadClicks = 0;
    },
  },
};
</script>

<style scoped>
.v-label {
  top: 3px !important;
}

#back-btn {
  position: fixed !important;
  top: calc(50% - 55px);
  left: -38px;
  z-index: 100;
  width: 60px !important;
  height: 60px !important;
  padding-left: 20px !important;
  background-color: white !important;
  border: 1px solid #ff6969;
  font-weight: 800 !important;
}

.text-color {
  color: #515c6f !important;
}

.text-font {
  font-family: Impact, Charcoal, sans-serif !important;
}
</style>
