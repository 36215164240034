<template>
  <div>
    <button
      @click="openDialog"
      class="btn-icon-vertical btn-square br-tr btn-transition btn btn-outline-focus"
    >
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['far', 'hat-chef']" />
      </i>
      <span v-if="showButtonText">{{ $t("erp.lang_save_order") }}</span>
    </button>

    <ButtonsDeleteKitchenmonitorOrdersDialog
      :pos-type="posType"
      :show-dialog="showDialog"
      @closeDialog="showDialog = false"
    />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHatChef } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faHatChef);

import { mapState } from "vuex";
import ButtonsDeleteKitchenmonitorOrdersDialog from "./ButtonsDeleteKitchenmonitorOrdersDialog.vue";

export default {
  name: "ButtonsDeleteKitchenmonitorOrders",

  props: {
    posType: String,
    showButtonText: Boolean,
  },

  components: {
    ButtonsDeleteKitchenmonitorOrdersDialog,
    "font-awesome-icon": FontAwesomeIcon,
  },

  data() {
    return {
      showDialog: false,
    };
  },

  computed: {
    ...mapState(["pos"]),
  },

  methods: {
    openDialog() {
      this.showDialog = true;
    },
  },
};
</script>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>
