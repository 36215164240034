<template>
  <v-dialog v-model="showDialog" persistent max-width="900">
    <v-card :disabled="loading">
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t("erp.lang_open_order") }}</v-toolbar-title>

        <v-spacer />

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider class="ma-0" />

      <v-card-text class="overflow-y-auto px-0" style="max-height: 400px">
        <v-container v-if="orders.length === 0 && !loading">
          <v-row>
            <v-col cols="12">
              <v-alert text color="primary" class="text-center">{{
                $t("generic.lang_noOrdersFound")
              }}</v-alert>
            </v-col>
          </v-row>
        </v-container>

        <v-data-table
          v-if="orders.length > 0"
          v-model="selected"
          item-key="uuid"
          :headers="dataTableHeaders"
          :items="orders"
          :loading="loading"
          :sort-by="['timestamp']"
          :sort-desc="[true]"
          class="elevation-1"
        >
          <template v-slot:item.timestamp="{ item }">
            {{ formatTS(item.timestamp) }}
          </template>
          <template v-slot:item.isTakeAway="{ item }">
            {{
              item.isTakeAway
                ? $t("generic.lang_offSiteSale")
                : $t("generic.lang_inHouseSale")
            }}
          </template>
          <template v-slot:item.crud="{ item }">
            <v-btn
              fab
              small
              color="error"
              depressed
              @click="deleteOrder(item.uuid)"
            >
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { ENDPOINTS } from "../../../config";
import { Events } from "../../../plugins/events";

export default {
  name: "ButtonsDeleteKitchenmonitorOrdersDialog",

  props: {
    showDialog: Boolean,
    posType: String,
    item: Object,
  },

  data() {
    return {
      loading: false,
      selected: [],
      orders: [],
      orderToDelete: null,
      showDeleteWarning: false,
      tseErrorMessage: "",
    };
  },

  computed: {
    ...mapState(["pos"]),
    ...mapGetters({
      fiscalClient: "tse/fiscalClient",
    }),

    dataTableHeaders() {
      return [
        {
          text: "#",
          value: "tableName",
        },
        {
          text: this.$t("generic.lang_pickUpNumber"),
          value: "pick_up_number",
        },
        {
          text: this.$t("generic.lang_Pagernummer"),
          value: "pager_number",
        },
        {
          text: this.$t("generic.lang_location"),
          value: "isTakeAway",
        },
        {
          text: this.$t("generic.lang_date"),
          value: "timestamp",
        },
        {
          text: "",
          value: "crud",
        },
      ];
    },
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.orders = [];
        this.getOrders();
      }
    },
  },

  methods: {
    formatTS(timestamp) {
      return this.$moment.unix(timestamp).format("DD.MM.YYYY HH:mm");
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    getOrders() {
      if (this.posType === "gastro") {
        this.loading = true;

        this.axios
          .post(ENDPOINTS.POS.GASTRO.KITCHENMONITOR_ORDERS.GET)
          .then((res) => {
            if (res.data.success) {
              this.orders = res.data.preorder;
            }
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deleteOrder(uuid) {
      this.$swal({
        title: this.$t("generic.lang_deletePreorder"),
        text: this.$t("generic.lang_deletePreorderMsg"),
        icon: "warning",
        confirmButtonText: this.$t("generic.lang_delete"),
        cancelButtonText: this.$t("generic.lang_cancel"),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading = true;

          this.axios
            .post(ENDPOINTS.POS.GASTRO.KITCHENMONITOR_ORDERS.DELETE, {
              uuid: uuid,
            })
            .then((res) => {
              if (res.data.success) {
                this.getOrders();
                this.$socket.emit("kitchenmonitor.refreshOrders");
              } else {
                Events.$emit("showSnackbar", {
                  message:
                    this.$t("generic.lang_errorOccurred") + ": " + res.data.msg,
                  color: "error",
                });
              }
            })
            .catch((err) => {
              Events.$emit("showSnackbar", {
                message:
                  this.$t("generic.lang_errorOccurred") + ": " + err.message,
                color: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        },
        allowOutsideClick: () => false,
      });
    },
  },
};
</script>
